<template>
<b-navbar-nav class="ml-auto">
    <b-nav-item-dropdown :text="$appSettings.lang.toUpperCase()" right>
        <template slot="button-content">
            <img width="auto" height="20px" :src="'/img/flags/' + $appSettings.lang + '.png'" />
        </template>
        <b-dropdown-item :class="$appSettings.lang === 'en' ? 'active' : ''" @click.stop="eventBus.$emit('current_language', 'en');switchLang('en')"><img :src="'/img/flags/en.png'" /></b-dropdown-item>
        <b-dropdown-item :class="$appSettings.lang === 'ro' ? 'active' : ''" @click.stop="eventBus.$emit('current_language', 'ro');switchLang('ro')"><img :src="'/img/flags/ro.png'" /></b-dropdown-item>
    </b-nav-item-dropdown>
</b-navbar-nav>
</template>

<script>
import {
    eventBus
} from '../../main'

export default {
    name: 'LangNavbarAuth',
    props: ['language'],
    data: function () {
        return {
            eventBus: eventBus,
        }
    },
    created: function () {
        this.updateCurrentLanguage()
    },
    methods: {
        updateCurrentLanguage: function () {
            if (this.language) {
                this.current_language = this.language.toUpperCase()
                this.switchLang(this.language)
            }
        }
    }
}
</script>
